import { FC, useEffect, useState } from "react";
import { AlertColor } from "@mui/material/Alert";

import axios from "axios";
import filtrooApi from "../../api/filtrooApi";
import GiveGoldScreenView from "./Views/GiveGoldScreen";

const GiveGoldScreen: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const [showAlert, setShowAlert] = useState(false);
  const [statusAlert, setStatusAlert] = useState<AlertColor>("success");
  const [
    msg,
    setMsg
  ] = useState<string>("");


  const givePoints = async (operationData:any) => {
    try{
      const res = await filtrooApi.post("/give-gold", operationData)
      return true
    }catch(error){
      console.log(error)
      return false
    }
  }

  const onSubmit = async (operationData:any) => {
    const created = await givePoints(operationData);
    if (created) {
      setShowAlert(true);
      setStatusAlert("success")
      setMsg("Gold given successfully")
      return created;
      
    } else {
      setStatusAlert("warning")
      setMsg("Error giving gold")
      setShowAlert(true);
      return undefined;
    }
  };


  return (
    <GiveGoldScreenView
      onSubmit={onSubmit}
      showAlert={showAlert}
      statusAlert={statusAlert}
      msg={msg}
      setShowAlert={setShowAlert}
      setStatusAlert={setStatusAlert}
    />
  );
};

export default GiveGoldScreen;
