import React, { useContext, useMemo, useState } from "react";
import {
  Users,
  LogOut,
  Grid,
  Upload,
  Tag,
  CheckSquare,
  Activity,
  DollarSign,
  Gift,

} from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/authContext/AuthContext";
import { useTranslation } from "../../contexts/LocalizeContext";
import "../../styles/components/leftMenu.scss";

const Tab = (props: {
  id: string;
  icon: React.ReactNode;
  onClick: any;
  withText?: string;
  active?: boolean;
}) => (
  <button
    style={props.active ? { backgroundColor: "#4592d3" } : {}}
    key={props.id}
    onClick={props.onClick}
    className="left-menu-container-b"
  >
    {props.icon}
    {props.withText && <span>{props.withText}</span>}
  </button>
);

export default function AdminLeftMenu() {
  const { signOut } = useContext(AuthContext);
  const { LAYOUT } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabs] = useState([
    {
      id: "users",
      icon: <Users style={{ color: "#FFF" }} />,
      url: "/admin/users",
    },
    {
      id: "categories",
      icon: <Grid style={{ color: "#FFF" }} />,
      url: "/admin/categories",
    },
    {
      id: "add filter",
      icon: <Upload style={{ color: "#FFF" }} />,
      url: "/admin/upload-filter",
    },
    {
      id: "approved filter",
      icon: <CheckSquare style={{ color: "#FFF" }} />,
      url: "/admin/approved-filter"
    },
    {
      id: "points",
      icon: <Tag style={{ color: "#FFF" }} />,
      url: "/admin/generate-points-code",
    },
    {
      id: "pointsRewards",
      icon: <Activity style={{ color: "#FFF" }} />,
      url: "/admin/points-rewards",
    },
    {
      id: "pointsGenerator",
      icon: <DollarSign style={{ color: "#FFF" }} />,
      url: "/admin/points-generator",
    },
    {
      id: "goldGiven",
      icon: <Gift style={{color: "#FFF"}} />,
      url: "/admin/give-gold"
    }
  ]);

  const renderTabs = useMemo(
    () =>
      tabs.map((i) => (
        <Tab
          key={i.id}
          active={i.url === location.pathname}
          onClick={() => navigate(i.url)}
          {...i}
        />
      )),
    [tabs, location]
  );

  return (
    <aside className="left-menu-container">
      <div className="left-menu-container-t">{renderTabs}</div>
      <Tab
        withText={LAYOUT.TABS.LOG_OUT}
        id="LOGOUT"
        onClick={signOut}
        icon={<LogOut style={{ color: "#FFF" }} />}
      />
    </aside>
  );
}
